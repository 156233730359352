@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

#root{
    display: flex;
    justify-content: center;
}

body{
    background-color: rgba(0, 0, 0, 1);
    color: white;
}

#ig-contact-svg-wrapper{
    fill:white;
}

@media (orientation: landscape){
    #navBar{
        height: 5%;
        margin: 0rem 0rem .5rem 0rem
    }
}

#footer{
    grid-area:footer;
}

a {
    text-decoration-color: none;
}



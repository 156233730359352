@media (orientation: landscape) {
  #about-container{
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 70vw;
    margin: auto;
    padding: 1rem 0rem;
  }

  #about-block{
    margin-top: 2vh;
    width: 60%
  }

  #about-img-container {
    height: auto;
    overflow: hidden;
  }
  
  #about-container img {
    max-width: 100%;
    max-height: 40vh;
    min-height: 35vh;
    display: block;
    border-radius: 0.25em;
  }
}

@media (orientation: portrait) {
  #about-container{
    display:flex;
    flex-flow:column;
    align-items: center;
    justify-content: space-between;
    width: 70vw;
    margin: auto;
    padding: 1rem 0rem;
  }

  #about-img-container {
    padding: 0em 0em 1rem 0em;
  }
  
  #about-container img {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: .25em;
  }
}
#work-container{
    grid-area: b;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    p {
        color:#52a376;
        font-size: calc((2 - 1) * 1vw + 1rem);
    }
    .click-me:hover{
        cursor: pointer;
    }
}

@media (orientation: landscape){
    #video-container{
        width: 100%;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        justify-content: space-between;
    }
    .video-item{
        margin: .25vh;
        > img {
            border-radius: .15rem;
            object-fit: cover;
            width: 100%;
            height: 27vh;
        }
    }
    .work-video-mobile{
        display: none;
    }
    .react-player__preview{
        width: 100%;
        height: 100%;
    }
    .image-only-work-item {
        width: 100%;
        height: 100%;
    }
}

@media (orientation: portrait){
    #video-container{
        display: flex;
        flex-flow: column;
    }
    .work-video-desktop{
        display: none;
    }
    .video-item{
        > img {
            border-radius: .15rem;
            width: 100%
        }
    }
}


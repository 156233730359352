#contact-landscape{
    display: flex;
    flex-flow: column;
    align-items: center;
    align-self: center;
    #contact-page-email {
        font-size: 1.5vw
    }
    * {
        padding: 5px;
    }
    #ig-contact-svg-wrapper {
        height: 8vh;
    }
}

#contact-portrait{
    padding-top: 10vh;
    display: flex;
    flex-flow: column;
    align-items: center;
    align-self: center;
    #contact-page-email {
        font-size: 5vw
    }
    #ig-contact-svg-wrapper {
        height: 10vh;
    }
}
#navBar-links {
    color:white;
    a {
        color:white;
    }
    a:visited {
        color:white;
    }
    .underline-me{
        text-decoration-line: underline;
    }
    .dont-underline-me{
        text-decoration-line: none;
    }
}

@media (orientation:portrait){
    #navBar-landscape{
        display: none;
    }
    #navBar-portrait{
        align-self: flex-start;
        grid-area: nav;
        z-index: 1000;
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        width: 90vw;
        justify-self: center;
        #logo-container{
            height: auto;
            width: min(15vw, 100px);
            margin: 0.5rem auto;
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
        #navBar-links{
            display: flex;
            justify-content: space-between;
            margin: 0px 5px;
            font-size: 1.25rem;
        }
    }

}

@media (orientation:landscape){
    #navBar-portrait{
        display: none;
    }
    #navBar-landscape{
        height: 5vh;
        grid-area: nav;
        z-index: 1000;
        display: flex;
        justify-content:space-between;
        padding-top:0.5rem;
        width: 90vw;
        justify-self: center;
        margin: 5rem 0rem;
        #logo-container{
            margin-left: 4rem;
            height: 15vh;
            width: auto;
            align-self: center;
        }
        #navBar-links{
            margin-right: 4rem;
            display: flex;
            justify-content: space-between;
            width: 25%;
        }
    }
}


@media screen and (min-width: 0px) and (max-width:1499px) and (orientation: landscape){
    #navBar-landscape p {
        font-size: calc((2 - 1) * 1vw + .5rem);
        margin: 0px 5px;
    }
    #navBar-links a{
        font-size: 1.5vw;
    }
}

@media screen and (min-width: 1500px) and (max-width: 2119px) and (orientation: landscape){
    #navBar-landscape p {
        font-size: calc((2 - 1) * 1vw + .5rem);
        margin: 0px 5px;
    }
    #navBar-links a{
        font-size: 1.5vw;
    }
}

@media screen and (min-width: 2120px) and (orientation: landscape){
    #navBar-landscape p {
        font-size: calc((2 - 1) * 1vw + 1rem);
        margin: 0px 5px;
    }
    #navBar-links a{
        font-size: 1vw;
    }
}
